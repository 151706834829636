import React, { useEffect, useState } from "react";
import "./styles/Home.css";
import FacebookLogin from "react-facebook-login";
import { isEmpty } from "./globalFunctions/isEmpty";

const responseFacebook = (response) => {
  console.log(response);
};

const componentClicked = (data) => {
  console.warn(data);
};

function Home({ store }) {
  const [response, setResponse] = useState("");

  return (
    <div className="home">
      <div className="wrapper">
        <div className="home__firstBar">
          <div className="home__firstBarLeft">Content Marketing Component</div>
          <div className="home__firstBarMiddle">
            <FacebookLogin
              appId="321069276772900"
              autoLoad={true}
              fields="name,email,picture"
              onClick={componentClicked}
              callback={responseFacebook}
            />
          </div>
          <div className="home__firstBarRight">Neuer Beitrag Component</div>
        </div>
        <div className="home__secondBar">
          <div className="home__secondBarLeft">Liste Component</div>
          <div className="home__secondBarRight">Beitrag Component</div>
        </div>
      </div>
    </div>
  );
}

export default Home;
