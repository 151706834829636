import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { MdClose, MdImportExport } from "react-icons/md";
import styled from "styled-components/macro";
import Home from "./Home";
import Login from "./Login";
import "./App.css";
import { Redirect } from "react-router";
import { isEmpty } from "./globalFunctions/isEmpty";

export default function App() {
  const [store, setStore] = useState();
  const [responseUser, setResponseUser] = useState("");
  const [userData, setUserData] = useState([]);

  const getData = () => {
    try {
      const jsonValue = localStorage.getItem("@jwtdigitool");
      var resJSON = jsonValue.substring(1, jsonValue.length - 1);
      setStore({ JWT: resJSON });
    } catch (e) {
      // error reading value
    }
  };

  const destroySession = () => {
    try {
      localStorage.removeItem("@jwtdigitool");
      setStore();
    } catch (e) {
      // saving error
    }
  };

  if (isEmpty(store) && localStorage.getItem("@jwtdigitool")) {
    getData();
  }

  //get userData
  useEffect(async () => {
    if (!isEmpty(store)) {
      const res = await fetch(
        "https://www.menschmeierapi.exconsilio.com/users/",
        {
          headers: {
            Authorization: `Bearer ${store.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseUser(JSON.parse(value)));
    }
  }, [store]);

  // collection in state packen
  useEffect(() => {
    if (responseUser !== "") {
      setUserData(responseUser);
    }
  }, [responseUser, store]);

  return (
    <div className="app">
      {/* Routing */}
      <Router>
        <div>
          <Switch>
            {/* Startseite: Home */}
            <Route exact path="/">
              <Home store={store} />
            </Route>
            {/* Unterseite: Über Uns */}
            <Route exact path="/login">
              {!isEmpty(store) ? (
                <Redirect to="/" />
              ) : (
                <Login store={store} setStore={setStore} />
              )}
            </Route>
          </Switch>
          {/* Navigationsleiste TOP */}
          {/* Navigationsleiste: Bottom */}
        </div>
      </Router>
    </div>
  );
}

const CloseIcon = styled(MdClose)`
  font-size: 33px;
  color: #000;
  width: auto;
  justify-content: center;
  padding-right: 18px;
  padding-top: 3px;
`;
